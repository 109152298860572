<template>
  <div>
    <a href="#">RU</a>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher"
}
</script>

<style scoped>

</style>